<script>
export default {
    name: "Title",

    props: {
        docName: {
            type: String,
            required: true
        },
        number: {
            type: Number,
            required: false,
            default: null,
        },
        date: {
            type: String,
            required: true
        },
        passedAt: {
            type: [String, null],
            required: false,
            default: null
        }
    }
}
</script>

<template>
    <h1>
        <v-icon v-if="passedAt" color="secondary">mdi-check-circle-outline</v-icon>{{ docName }} {{ number }} {{ $t('document_from') }} {{date}}
    </h1>
</template>

<style scoped lang="scss">

</style>
